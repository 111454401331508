import { Controller } from "@hotwired/stimulus";

import type { FrameElement } from "@hotwired/turbo";
import { Temporal } from "@js-temporal/polyfill";
import { embedPartnerPortalAnalyticsPath } from "src/routes";

export default class AnalyticsController extends Controller {
  static targets = ["frame"];
  declare frameTarget: FrameElement;

  storeIds : number[] = [];
  rangeStart : Temporal.PlainDate | null = null;
  rangeEnd : Temporal.PlainDate | null = null;

  initStoreIds({
    detail: { storeIds }
  }: {
    detail: { storeIds: number[]};
  }) {
    this.storeIds = storeIds;
  }

  initDates({
    detail: { rangeStart, rangeEnd }
  }: {
    detail: { rangeStart: Temporal.PlainDate; rangeEnd: Temporal.PlainDate };
  }) {
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
  }

  updateStores({ 
    detail: { storeIds }
  }: {
      detail: { storeIds: number[] };
  }) {
    this.storeIds = storeIds;
    this.reload();
  }

  updateDates({
    detail: { rangeStart, rangeEnd }
  }: { 
    detail: { rangeStart: Temporal.PlainDate; rangeEnd: Temporal.PlainDate };
  }) {
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
    this.reload();
  }

  reload() {
    this.frameTarget.setAttribute("src", embedPartnerPortalAnalyticsPath({
      "update_store_ids[]": "", // So that the param is always present, even when given an empty array next line down
      update_store_ids: this.storeIds,
      update_start_date: this.rangeStart?.toString(),
      update_end_date: this.rangeEnd?.toString(),
    }));
  }
}

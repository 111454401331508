import { Controller } from "@hotwired/stimulus";
import { visit } from "@hotwired/turbo"

export default class SelectnavController extends Controller<HTMLSelectElement> {
  static targets = ["select"]
  static values = {
    targetTurboFrame: String
  }

  declare targetTurboFrameValue: string;
  declare selectTarget: HTMLSelectElement;

  navigate() {
    if (this.targetTurboFrameValue != "") {
      visit(this.selectTarget.value, { frame: this.targetTurboFrameValue })
    } else {
      visit(this.selectTarget.value)
    }
  }
}

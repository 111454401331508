import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "help"]

  declare selectTarget : HTMLSelectElement;
  declare helpTargets : HTMLElement[];

  connect() {
    this.toggleHelpText()
  }

  toggleHelpText() {
    const selected = this.selectTarget.value;

    this.helpTargets.forEach((helpText) => {
      if (helpText.dataset.report === selected) {
        helpText.classList.remove("is-hidden")
      } else {
        helpText.classList.add("is-hidden")
      }
    })
  }
}

import "./stylesheets/partner_portal.scss";
import "./stylesheets/availability_visualisation.scss";

// Configure Stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();

import "src/admin_cable";

const sharedContext = require.context("controllers/shared", true, /\.(ts|js)$/);
application.load(definitionsFromContext(sharedContext));

const restaurantAdminContext = require.context("controllers/partner_portal", true, /\.(ts|js)$/);
application.load(definitionsFromContext(restaurantAdminContext));

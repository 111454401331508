import { Controller } from "@hotwired/stimulus";
import { createElement } from "react";
import { createRoot } from "react-dom/client";

import { DateSelect } from "../../src/date_select";
import { Temporal } from "@js-temporal/polyfill";

export default class DateSelectController extends Controller {
  static targets = ["select", "startInput", "endInput"];

  declare selectTarget: HTMLSpanElement;
  declare startInputTarget: HTMLInputElement;
  declare endInputTarget: HTMLInputElement;

  connect() {
    const dateStart = Temporal.PlainDate.from(this.startInputTarget.value);
    const dateEnd = Temporal.PlainDate.from(this.endInputTarget.value);

    const root = createRoot(this.selectTarget);

    root.render(createElement(DateSelect, {
      dateStart: dateStart,
      dateEnd: dateEnd,
      onDatesChanged: this.onChange.bind(this),
    }));

    this.dispatch("init", { detail: { rangeStart: dateStart, rangeEnd: dateEnd } });
  }

  onChange(rangeStart: Temporal.PlainDate, rangeEnd: Temporal.PlainDate) {
    this.startInputTarget.value = rangeStart.toString();
    this.endInputTarget.value = rangeEnd.toString();
    this.dispatch("change", { detail: { rangeStart: rangeStart, rangeEnd: rangeEnd } });
  }
}

import React, { useState, useCallback } from "react";
import { MantineProvider, createTheme } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { Temporal } from "@js-temporal/polyfill";

const DateSelect = ({
dateStart, dateEnd, onDatesChanged
}: {
    dateStart: Temporal.PlainDate;
    dateEnd: Temporal.PlainDate;
    onDatesChanged: (start: Temporal.PlainDate, end: Temporal.PlainDate) => void;
  }) => {
  const leftIcon = (<span className="icon"><i className="fas fa-calendar"></i></span>);
  const rightIcon = (<span className="icon"><i className="fas fa-chevron-down"></i></span>);

  const startMillis = dateStart.toZonedDateTime(Temporal.Now.timeZone()).epochMilliseconds;
  const endMillis = dateEnd.toZonedDateTime(Temporal.Now.timeZone()).epochMilliseconds;
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(startMillis), new Date(endMillis)]);

  const onChange = useCallback((value: DatesRangeValue) => {
    setDateRange(value);

    if (value[0] !== null && value[1] !== null) {
      const start = Temporal.Instant.from(value[0].toISOString()).toZonedDateTimeISO(Temporal.Now.timeZone()).toPlainDate();
      const end = Temporal.Instant.from(value[1].toISOString()).toZonedDateTimeISO(Temporal.Now.timeZone()).toPlainDate();
      onDatesChanged(start, end);
    }
  }, [onDatesChanged]);


  return (
    <MantineProvider theme={createTheme({})}>
      <DatePickerInput
        type="range"
        value={dateRange}
        leftSection={leftIcon}
        rightSection={rightIcon}
        onChange={onChange}
        allowSingleDateInRange={true}
      />
    </MantineProvider>
  );
}

export { DateSelect };

import { Controller } from "@hotwired/stimulus";
import { createElement } from "react";
import { createRoot } from "react-dom/client";

import { StoreFilter } from "../../src/store_filter";

export default class StoreFilterController extends Controller {
  static targets = ["config", "filter"];

  declare configTarget: HTMLScriptElement;
  declare filterTarget: HTMLSpanElement;

  config: { currentlySelectedStores: number[]; stores: { id: number; name: string }[] } = { currentlySelectedStores: [], stores: [] };

  connect() {
    this.config = JSON.parse(this.configTarget.innerText);

    const root = createRoot(this.filterTarget);
    
    root.render(createElement(StoreFilter, { 
      currentlySelectedStores: this.config.currentlySelectedStores,
      stores: this.config.stores,
      onStoresChanged: this.onChange.bind(this)
    }));

    this.dispatch("init", { detail: { storeIds: this.config.currentlySelectedStores } });
  }

  onChange(newValues: number[]) {
    this.dispatch("change", { detail: { storeIds: newValues } });
  }
}

import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';
import { ChartData, ChartOptions, ChartType } from "chart.js";

type GraphType = "line" | "bar" | "doughnut" | "pie";

export default class extends Controller {
  static targets = ["chart", "data", "options"];

  declare chartTarget : HTMLElement | null;
  declare dataTarget : HTMLElement | null;
  declare optionsTarget : HTMLElement | null;

  chartData: ChartData | null = null;
  chartOptions : ChartOptions | null = null;
  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  chart: Chart | null = null;
  graphType : GraphType = "line";

  connect() {
    if (!this.dataTarget || !this.optionsTarget || !this.chartTarget) {
      throw "Can't render a graph without data and config";
    }

    this.chartData = JSON.parse(this.dataTarget.innerText);
    this.chartOptions = JSON.parse(this.optionsTarget.innerText);

    this.canvas = document.createElement("canvas");
    this.chartTarget.appendChild(this.canvas);

    this.canvasContext = this.canvas.getContext("2d");

    this.graphType = (this.data.get("type") || "line") as GraphType;

    this.initialiseChart();
  }

  initialiseChart() {
    if (!this.chartData || !this.chartOptions || !this.canvasContext) {
      throw "Trying to configure graph before loading config";
    }

    if (this.graphType == "pie") {
      if (this.chartOptions.plugins) {
        this.chartOptions.plugins.tooltip = {
          callbacks: {
            label: function(context) {
              return context.dataset.data[context.dataIndex] + "%"
            }
          }
        }
      }
    }

    this.chart = new Chart(this.canvasContext, {
      type: this.graphType as ChartType,
      data: this.chartData,
      options: this.chartOptions
    });
  }
}
